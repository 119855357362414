<template>
  <v-container>
    <v-progress-linear indeterminate color="teal" v-if="isLoading"></v-progress-linear>

    <v-stepper v-model="step" color="orange">

      <!-- Stepper header -->
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Upload
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" step="2">
          Preview
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 3" step="3">
          Results
        </v-stepper-step>
      </v-stepper-header>

      <!-- Stepper content -->
      <v-stepper-items>

        <!-- Prompt for upload -->
        <v-stepper-content step="1">

          <v-file-input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="Upload XLSX file" v-model="file">
          </v-file-input>

          <v-btn color="primary" @click="previewUpload" :disabled="!file">
            Preview
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <SSRTable ref="request-list" :data="table.requests.rows" :schema="colsToSchema(table.requests.cols)">
          </SSRTable>
          <v-btn color="warning" @click="upload">
            Confirm and Upload
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <SSRTable ref="result-list" :data="table.results.rows" :schema="table.results.schema">
            <div slot="merchantBinded" slot-scope="{item}">
              <span v-if="!item.merchantBinded" class="red--text">N/A</span>
              <span v-else class="green--text">{{ item.merchantBinded }}</span>
            </div>
          </SSRTable>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import ScopeSelector from '@/components/ScopeSelector';

import readXlsxFile from 'read-excel-file';
import axios from 'axios';

export default {
  name: 'BulkCreatePaymentServices',
  props: {
    service: {
      type: String,
      default: 'linepay'
    },
    org: Object
  },
  components: {
    SSRTable,
    ScopeSelector
  },

  mounted() {

  },

  methods: {
    previewUpload() {

      readXlsxFile(this.file)
      .then(rows => {
        // Populate the preview table.
        let cols = rows.slice(0, 1)[0];
        console.log(`[BulkPaymentServices] readXlsxFile header columns`, cols);
        console.log(`[BulkPaymentServices] readXlsxFile # of rows`, rows.length);

        this.table.requests.cols = cols;
        rows.shift();
        rows.forEach(cells => {
          let temp = { };
          for (let i=0; i<cells.length; i++) {
            let key = cols[i];
            temp[key] = cells[i];
          }
          this.table.requests.rows.push(temp);
        });
        
        // Advance to step 2.
        this.step = 2;
      })
      .catch(err => {
        console.error(`[BulkPaymentServices] readXlsxFile failed`, err);
        this.$notify({
          text: `Cannot read this file: ${err.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.$refs['request-list'].loadData();
      });
    },

    upload() {
      // Prepare request body.
      let body = {
        orgId: this.org['id'],
        service: this.service,
        requests: this.table.requests.rows
      };

      // Do the bulk-create request.
      this.isLoading = true;
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs/${this.org['alias']}/payment-services/bulk`,
        body
      )
      .then(response => {

        // Process the bulk result.
        let { insertedPaymentServices = [ ], insertedMerchantPaymentConfigs = [ ] } = response.data;
        insertedPaymentServices.forEach(ps => {
          this.insertedPaymentServices[ ps['id'] ] = ps['name'];
        });
        insertedMerchantPaymentConfigs.forEach(mpc => {
          this.insertedMerchantPaymentConfigs[ mpc['merchant_id'] ] = mpc;
        });

        // Notify and advance to next step.
        this.$notify({
          text: `Upload done. Please verify the results.`,
          type: 'success'
        });
        this.showResults();
        this.step = 3;
      })
      .catch(err => {
        this.$notify({
          text: `Failed to upload: ${err.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

    showResults() {
      // Combine the bulk-create results and the previous request.
      // Append results to the previously requested rows.
      this.table.results.rows = this.table.requests.rows;
      this.table.results.rows.forEach(row => {
        let merchantId = row['merchantId'];//may be undefined.
        if (merchantId)
          row['merchantBinded'] = this.insertedMerchantPaymentConfigs[ merchantId ]['name'];
      });

      // Append schema.
      let schema = this.colsToSchema(this.table.requests.cols);
      this.table.results.schema = schema.concat([ { value: 'merchantBinded', text: 'Merchant Binded' } ]);

      // Force refresh the results table.
      console.log(`Results schema`, this.table.results.schema);
      console.log(`Results rows`, this.table.results.rows);
      this.$refs['result-list'].loadData();
    },

    colsToSchema(cols) {
      return cols.map(col => {
        return { value: col, text: col };
      });
    }
  },

  data() {
    return {
      isLoading: false,
      step: 1,
      file: undefined,

      table: {
        requests: {
          rows: [ ],
          cols: [ ]
        },
        results: {
          rows: [ ],
          // cols: [ ]
          schema: [ ]
        }
      },

      insertedPaymentServices: { },
      insertedMerchantPaymentConfigs: { }

    };
  }
}
</script>